/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles
} from '@material-ui/core';
import Logo from 'src/components/Logo';
import HomeIcon from '@material-ui/icons/Home';
import TimelineIcon from '@material-ui/icons/Timeline';
import BallotIcon from '@material-ui/icons/Ballot';
import BuildIcon from '@material-ui/icons/Build';
import FolderIcon from '@material-ui/icons/Folder';
import SettingsIcon from '@material-ui/icons/Settings';
import NavItem from './NavItem';

const sections = [
  {
    items: [
      {
        title: 'Home',
        icon: HomeIcon,
        href: '/'
      },
      {
        title: 'Analytics',
        icon: TimelineIcon,
        href: '/analytics',
        items:[
          {
            title: 'Asset Condition',
            href: '/asset-condition'
          }
        ]
      },
      {
        title: 'Projects',
        icon: BallotIcon,
        href: '/projects'
      },
      {
        title: 'RepairFlow',
        icon: BuildIcon,
        href: '/RepairFlow'
      },
      {
        title: 'Libraries',
        icon: TimelineIcon,
        href: '/libraries',
        items:[
          {
            title: 'Default Library',
            href: '/default-library'
          },
          {
            title: 'Reference Files',
            href: '/reference-files'
          },

        ]
      },
      {
        title: 'divider',
        href: '#'
      },
      {
        title: 'Assset Management',
        icon: FolderIcon,
        href: '/asset-management',
        items:[
          {
            title: 'Assets',
            href: '/assets'
          },
          {
            title: 'Companies',
            href: '/companies'
          },

        ]
      },
      {
        title: 'User Management',
        icon: FolderIcon,
        href: '/asset-management',
        items:[
          {
            title: 'Users',
            href: '/users'
          },
        ]
      },
      {
        title: 'Configuration',
        icon: SettingsIcon,
        href: '/configuration',
        items:[
          {
            title: 'Checklist Templates',
            href: '/checklist-templates'
          },
          {
            title: 'Asset Types',
            href: '/asset-types'
          },
          {
            title: 'Project Types',
            href: '/project-types'
          },
          {
            title: 'Report Types',
            href: '/report-types'
          },
          {
            title: 'Defect Profiles',
            href: '/defect-profiles'
          },
        ]
      },

    ]
  },
];

function renderNavItems({
  items,
  pathname,
  depth = 0
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box pb={5}>
          </Box>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
