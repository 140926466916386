import React from 'react';

const HeaderLogo = (props) => {
  return (
    <img
      alt="Logo"
      src="/static/Huvr-Logo-Horizontal.svg"
      {...props}
      style={{maxHeight: '35px'}}
    />
  );
}

export default HeaderLogo;
